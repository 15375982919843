import { render, staticRenderFns } from "./gathering.vue?vue&type=template&id=ca291a26&scoped=true&"
import script from "./gathering.vue?vue&type=script&lang=js&"
export * from "./gathering.vue?vue&type=script&lang=js&"
import style0 from "./gathering.vue?vue&type=style&index=0&id=ca291a26&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca291a26",
  null
  
)

export default component.exports