<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
              <el-date-picker
      v-model="value1"
      type="date"
      placeholder="选择日期"
      @change="changeValue"
      value-format="yyyy-MM-dd"
      >
    </el-date-picker>
        </el-col>
        <el-col :span="8">
          <!-- <el-button type="primary" @click="addOpen()">添加</el-button> -->
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
<el-table-column prop="day_order_num" label="日订单量"></el-table-column>
<el-table-column prop="day_order_payment_amount" label="日实付金额"></el-table-column>
        <el-table-column prop="day_order_total_prices" label="日总销量额"></el-table-column>
        <el-table-column prop="month_order_num" label="月订单量"></el-table-column>
        <el-table-column prop="month_order_payment_amount" label="月实付金额"></el-table-column>
        <el-table-column prop="month_order_total_prices" label="月总销量额"></el-table-column>
        <el-table-column prop="year_order_num" label="年订单量"></el-table-column>
        <el-table-column prop="year_order_payment_amount" label="年实付金额"></el-table-column>
        <el-table-column prop="year_order_total_prices" label="年总销量额"></el-table-column>
        <!-- <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="editOpen(scope.row)"
            ></el-button>
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              @click="delOpen(scope.row)"
            ></el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {PostOrderStatistics} from "@/api/gathering.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      value1:'',//时间值
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 7,
      },
      //表单名称长度
      formLabelWidth: "120px",
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: "",
      //表格数据
      tableData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
          //获取当前年月日
    getDate(){
       var date = new Date();
       var year=date.getFullYear(); //获取完整的年份(4位)
       var month=date.getMonth()+1; //获取当前月份(0-11,0代表1月)
       var day=date.getDate(); //获取当前日(1-31); //获取当前月份(0-11,0代表1月)
       var time=year+'-'+month+'-'+day;  //YYYY-MM-dd
       return time;//返回当前日期YYYY-MM-dd
    },
    //修改时间值
    changeValue(){
         this.PostOrderStatistics({date: this.value1})
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value);
      this.getInfo.page_num = value;
          this.PostOrderStatistics({date: this.value1})
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
    },


    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        w_id: "",
        area: "",
        seat: "",
      };
      console.log("close");
    },
    /*-----------------------------网络请求函数---------------------------------- */
    async  PostOrderStatistics(data){
      let res=await PostOrderStatistics(data);
        if(res.data.code==1){
          this.tableData=[ ]
          this.tableData.push(res.data.data);
        }else{
                  this.$message.error(res.data.message);

        }
    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var date=this.getDate();
    this.value1=date;
    this.PostOrderStatistics({date: this.value1})
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
</style>